
// Home
section.home {
  header.page {
    overflow: hidden;
    @media (min-width: $screen-lg-min) {
      height: 735px;
    }
    h1 {
      margin-top: 20px;
      margin-bottom: 10px;
    }
    h2 {
      margin-top: 20px;
      margin-bottom: 10px;
    }
    .button-container {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 20px;
      text-align: center;
      @media (min-width: $screen-md-min) {
        bottom: 40px;
      }
      @media (min-width: $screen-lg-min) {
        bottom: 80px;
      }
    }
    video {
      display: none;
      position: absolute;
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      z-index: 0;
      @media (min-width: $screen-sm-min) {
        display: block;
      }
    }
  }
  section.home-text {
    text-align: center;
    background-color: $brand-primary;
    section.text {
      margin: 0;
    }
    .container {
      @media (min-width: $screen-md-min) {
        width: 620px;
      }
    }
    .text {
      padding: 30px 0;
      font-size: 24px;
      color: $white;
      @media (min-width: $screen-sm-min) {
        padding: 50px 0;
      }
    }
  }
  section.home-featureds {
    color: $grey;
    .container {
      &:after {
        content: '';
        border-bottom: 1px solid $gray-lighter;
        display: block;
      }
    }
    .row {
      font-size: 0;
      text-align: center;
      > div {
        float: none;
        display: inline-block;
        vertical-align: bottom;
        margin: 10px 0;
        @media (min-width: $screen-sm-min) {
          margin: 0 0 50px;
          width: 20%;
        }
        @media (min-width: $screen-md-min) {
          width: calc(100% / 7);
        }
      }
    }
    h5 {
      margin-top: 15px;
    }
    img {
      max-height: 65px;
    }
  }
  section.home-quotes {
    margin: 20px 0 30px;
    @media (min-width: $screen-sm-min) {
      margin: 50px 0 40px;
    }
    .container {
      &:after {
        content: '';
        margin-top: 30px;
        border-bottom: 1px solid $gray-lighter;
        display: block;
        @media (min-width: $screen-sm-min) {
          margin-top: 40px;
        }
      }
    }
    .video-iframe {
      @media (min-width: $screen-md-min) {
        float: right;
      }
    }
  }
  section.home-instagram {
    margin-bottom: 40px;
    @media (min-width: $screen-sm-min) {
      margin-bottom: 100px;
    }
    header {
      text-align: center;
      margin-bottom: 30px;
      @media (min-width: $screen-sm-min) {
        margin-bottom: 50px;
      }
      a {
        color: $text-color;
        > img {
          display: inline-block;
          margin-right: 30px;
        }
        > div {
          display: inline-block;
          vertical-align: middle;
          text-align: left;
          :nth-child(1) {
            @extend .second-font;
            font-weight: bold;
          }
          :nth-child(2) {
            color: $grey;
          }
        }
      }
    }
    [class*="col-"] {
      margin-bottom: 15px;
      a {
        display: block;
        width: 100%;
        height: 115px;
        background-size: cover;
        background-position: center;
        @media (min-width: $screen-sm-min) {
          height: 320px;
        }
        @media (min-width: $screen-md-min) {
          height: 300px;
        }
        @media (min-width: $screen-lg-min) {
          height: 340px;
        }
      }
    }
  }
}
