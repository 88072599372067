header.page.dp_list {
    overflow: hidden;
    height:200px;
    top:46px;
&:before{background-color:transparent !important;z-index:auto;}
    h1 {
      margin-top: 5px;
      margin-bottom: 10px;
      color:#900021;
      font-size:32px;
    }
    ul {
      font-size: 14px;
      color:#202020;
	font-weight:bold;
      font-family: Metropolis-ExtraLight,sans-serif;

    }

    @media (min-width: $screen-lg-min) {
      height: 266px;
      top: 139px;
      margin-bottom: 0px;

    h1 {
      margin-top: 0px;
      margin-bottom: 30px;
      color:#900021;
      font-size:40px;
    }
    ul {
      font-size: 20px;
      color:#202020;
	font-weight:bold;
      font-family: Metropolis-ExtraLight,sans-serif;

    }
  }
}

header.page.dp {
    overflow: hidden;
    height:245px;
    top:46px;
&:before{background-color:transparent !important;z-index:auto;}
    h1 {
      margin-top: -35px;
      margin-bottom: 10px;
      color:#900021;
      font-size:32px;
    }
    ul {
      font-size: 14px;
      color:#202020;
      font-weight:bold;
      font-family: Metropolis-ExtraLight,sans-serif;	
    }

    @media (min-width: $screen-lg-min) {
      height: 287px;
      top: 80px;
      margin-bottom: 79px;
    
    h1 {
      margin-top: 0px;
      margin-bottom: 30px;
      color:#900021;
      font-size:40px;
    }
    ul {
      font-size: 20px;
      color:#202020;
      font-weight:bold;
      font-family: Metropolis-ExtraLight,sans-serif;
    }
  }
}
header.page.corona {
    overflow: hidden;
    height:94px;
    color:#202020;
    top:46px;
    &:before{background-color:transparent !important;z-index:auto;}

    h1 {
      margin-top:6px !important;
      margin-bottom: 10px !important;
      color:#900021;
      font-size:16px;
    }
    ul {
      font-size:12px;
    }

    @media (min-width: $screen-lg-min) {
      height: 80px;
      top: 80px;
      margin-bottom: 0px;
      h1 {
      	margin-top:0px !important;
      	font-size:22px !important;
      	margin-bottom: 10px !important;
      	color:#900021;
      }
      ul {
      font-size:16px;
      }
    }
}
header.page.dp_det {
    overflow: hidden;
    height:160px !important;
&:before{background-color:transparent !important;z-index:auto;}
    h1 {
      margin-top: 5px;
      margin-bottom: 10px;
      color:#900021;
      font-size:32px;
    }
    ul {
      font-size: 16px;
      color:#202020;
    }

    @media (min-width: $screen-lg-min) {
      height: 160px;
      margin-bottom: 20px;

    h1 {
      margin-top: 0px;
      margin-bottom:0px;
      color:#900021;
      font-size:34px;
    }
    ul {
      font-size: 22px;
      color:#202020;
    }
  }
}

