
// Featureds
section.featureds {
  margin-bottom: 45px;
  .btn-group {
    display: none;
  }
  .building {
    .featureds {
      display: none;
    }
  }
  > .container {
    > .row {
      overflow: hidden;
      > div {
        > .building {
          @media (min-width: $screen-md-min) {
            margin-bottom: -99999px;
            padding-bottom: 99999px;
          }
        }
      }
    }
  }
  .btn-container {
    > span {
      margin-left: 10px !important;
    }
  }
  .left-col {
    .title {
      @media (min-width: $screen-md-min) {
        font-size: 30px;
        height: 40px;
      }
    }
    .price {
      @media (min-width: $screen-md-min) {
        font-size: 26px;
      }
    }
    .description {
      @media (min-width: $screen-md-min) {
        font-size: 14px;
        height: 60px;
      }
    }
    .img {
      @media (min-width: $screen-md-min) {
        height: 315px;
      }
    }
    .btn {
      background-color: $text-color;
      border-color: $text-color;
      &:hover {
        background-color: $brand-primary;
        border-color: $brand-primary;
      }
    }
  }
  .right-col {
    > .row {
      > div {
        &:last-child {
          .building {
            @media (min-width: $screen-md-min) {
              margin-bottom: 0;
            }
          }
        }
      }
    }
    .btn-container {
      > a {
        @media (min-width: $screen-md-min) {
          border-radius: 50%;
          padding: 0px 6px;
        }
        span {
          @media (min-width: $screen-md-min) {
            display: none;
          }
        }
      }
    }
    .building {
      > a {
        @media (min-width: $screen-md-min) {
          width: 50%;
          float: left;
        }
      }
      > .content {
        @media (min-width: $screen-md-min) {
          width: 50%;
          float: left;
        }
      }
      .img {
        @media (min-width: $screen-md-min) {
          height: 200px;
        }
      }
    }
    h5 {
      @media (min-width: $screen-md-min) {
        margin: 0 !important;
        font-size: 20px !important;
      }
      @media (min-width: $screen-md-min) {
        margin: 10px 0 0 !important;
      }
    }
    .title {
      @media (min-width: $screen-md-min) {
        height: 50px;
      }
    }
    .price {
      @media (min-width: $screen-md-min) {
        font-size: 20px !important;
      }
      @media (min-width: $screen-md-min) {
        margin-bottom: 10px;
      }
    }
    .description {
      @media (min-width: $screen-md-min) {
        display: none;
      }
    }
    .zone {
      @media (min-width: $screen-md-min) {
        display: block;
      }
    }
  }
}