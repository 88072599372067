
// Categories
section.categories {
  padding-bottom: 10px;
  background-color: $bg-primary;
  h3 {
    font-weight: bold;
  }
  h3, h4 {
    @extend .second-font;
    color: $brand-primary;
    text-transform: uppercase;
  }
  header {
    padding: 5px 0 15px;
  }
  .category {
    display: block;
    padding: 25px 35px;
    background-color: $white;
    margin-bottom: 20px;
    &:hover {
      h3 {
        color: $brand-primary;
      }
      path {
        fill: $brand-primary;
      }
    }
    @media (min-width: $screen-sm-min) {
      margin-bottom: 30px;
      padding: 50px;
    }
    h3 {
      color: $text-color;
      text-transform: none;
      margin: 25px 0 0;
    }
  }
  figure {
    position: relative;
    height: 66px;
    svg {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      max-width: 60px;
    }
  }
  svg {
    path {
      fill: #616160;
    }
  }
}