
// Page
header.page {
  position: relative;
  height: 380px;
  background-size: cover;
  background-position: center;
  color: $white;
  @extend .second-font;
  @media (min-width: $screen-sm-min) {
    height: 460px;
  }
  @media (min-width: $screen-md-min) {
    height: 600px;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.25);
    z-index: 1;
  }
  h1 {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 0;
    @media (min-width: $screen-sm-min) {
      font-size: 50px;
    }
    @media (min-width: $screen-sm-min) {
      margin-top: 70px;
    }
  }
  h2 {
    font-size: 22px;
    margin: 15px 0 25px;
    @media (min-width: $screen-sm-min) {
      font-size: 26px;
      width: 700px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .center-container {
    position: relative;
    z-index: 2;
  }
  .red-line {
    width: 70px;
    height: 5px;
    background-color: $brand-primary;
    margin: 0 auto;
  }
  .icon {
    svg {
      max-width: 58px;
    }
  }
}

section.text {
  @extend .primary-font-extra-light;
  font-size: 20px;
  padding: 15px 0 5px;
  text-align: center;
  @media (min-width: $screen-sm-min) {
    padding: 35px 0 25px;
  }
  .content {
    margin-left: auto;
    margin-right: auto;
    max-width: 700px;
  }
  img {
    margin: 10px 0 20px;
  }
}
