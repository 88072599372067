
// Buildings
section.buildings {
  margin-bottom: -30px;
  .building {
    position: relative;
    color: $grey;
    background-color: $bg-primary;
    margin-bottom: 20px;
    @media (min-width: $screen-sm-min) {
      margin-bottom: 30px;
    }
    > a {
      display: block;
      overflow: hidden;
      .img {
        @include transition(all $transition-time-lg);
        &:hover {
          @include scale(1.03);
        }
      }
    }
    .content {
      padding: 20px;
    }
    .img {
      height: 180px;
      background-size: cover;
      background-position: center;
      @media (min-width: $screen-sm-min) {
        height: 230px;
      }
      @media (min-width: $screen-md-min) {
        height: 200px;
      }
      @media (min-width: $screen-lg-min) {
        height: 230px;
      }
    }
    .zone {
      @extend .second-font;
      text-transform: uppercase;
    }
    .title {
      color: $black;
      @extend .second-font;
      font-size: 25px;
      font-weight: bold;
      margin: 25px 0 15px;
      @media (min-width: $screen-sm-min) {
        height: 54px;
        overflow: hidden;
      }
    }
    .price {
      @extend .primary-font-bold;
      color: $brand-primary;
      font-size: 20px;
      margin-bottom: 30px;
    }
    .featureds {
      display: none;
      margin-bottom: 24px;
      border-bottom: 1px solid $grey;
      @media (min-width: $screen-sm-min) {
        display: block;
      }
      li {
        display: inline-block;
        margin-right: 15px;
        padding-bottom: 24px;
      }
      img {
        margin-right: 5px;
        height: 29px;
      }
      .parking {
        display: none;
      }
    }
    .description {
      display: none;
      font-size: 13px;
      height: 54px;
      overflow: hidden;
      margin-bottom: 40px;
      @media (min-width: $screen-sm-min) {
        display: block;
      }
    }
    .btn-container {
      position: relative;
      > span {
        font-size: 13px;
        margin-left: 25px;
      }
      .btn-group {
        float: right;
      }
      img {
        display: none;
        cursor: pointer;
        @media (min-width: $screen-sm-min) {
          display: inline-block;
        }
      }
      .dropdown-menu {
        min-width: 100px;
        font-size: 12px;
        box-shadow: none;
        a {
          padding: 3px 10px;
        }
        i {
          text-align: center;
          width: 12px;
        }
      }
    }
  }
  .view-more {
    display: block;
    @extend .second-font;
    font-weight: bold;
    color: $white;
    background-color: $black;
    margin: 0 auto;
    text-transform: uppercase;
    margin-bottom: 25px;
  }
}
