
// Services
section.services {
  .row {
    @media (min-width: $screen-sm-min) {
      overflow: hidden;
      margin-bottom: 50px;
    }
    > div {
      margin-bottom: 20px;
      @media (min-width: $screen-sm-min) {
        margin-bottom: 0;
      }
    }
  }
  .service {
    display: block;
    background-color: $bg-primary;
    @media (min-width: $screen-sm-min) {
      margin-bottom: -99999px;
      padding-bottom: 99999px;
    }
    &:hover {
      h2 {
        color: $brand-primary;
      }
    }
  }
  h2 {
    color: $text-color;
    margin-top: 0;
    @extend .second-font;
    font-size: 30px;
    font-weight: bold;
  }
  .content {
    padding: 40px 25px 25px;
    @media (min-width: $screen-sm-min) {
      padding: 85px 50px 50px;
    }
    p {
      color: $grey;
    }
  }
  .line {
    width: 70px;
    height: 5px;
    margin: 20px auto 30px;
    background-color: $brand-primary;
  }
}