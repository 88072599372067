
// Paginator
.paginator-container {
  .row {
    > div {
      margin: 20px 0;
      @media (min-width: $screen-sm-min) {
        margin: 40px 0;
      }
    }
  }
  .paginator {
    @media (min-width: $screen-sm-min) {
      text-align: right;
    }
    > div {
      display: inline-block;
    }
  }
  .paginator-pages {
    color: $paginator-color;
    > div {
      display: inline-block;
      margin: 0 2px;
    }
    a {
      color: $paginator-color;
      &:hover {
        color: $brand-primary;
      }
    }
  }
  .sel_lnk {
    color: $brand-primary;
  }
  .fa {
    color: $paginator-color;
  }
}