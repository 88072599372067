
// Detail
section.detail {
  @media (min-width: $screen-sm-min) {
    margin-bottom: 100px;
  }
  header.page {
    cursor: -moz-zoom-in;
    cursor: -webkit-zoom-in;
    cursor: zoom-in;
    @media (max-width: $screen-sm-min - 1) {
      height: 260px;
    }
    &:before {
      display: none;
    }
  }
  section.subheader {
    width: 100%;
    background-color: #f3f3f3;
    background-color: $bg-primary;
    padding: 15px 0;
    z-index: 2;
    text-align: center;
    @media (min-width: $screen-sm-min) {
      position: fixed;
      top: 47px;
      padding: 6px 0;
      text-align: left;
    }
    @media (min-width: $screen-md-min) {
      top: 80px;
    }
    .row {
      font-size: 0;
    }
    [class*="col-"] {
      float: none;
      display: inline-block;
      vertical-align: middle;
      font-size: $font-size-base;
    }
    .col-sm-3 {
      margin-top: 10px;
      text-align: center;
      @media (min-width: $screen-sm-min) {
        margin-top: 0;
        text-align: right;
      }
    }
    img {
      width: 12px;
      margin-left: 10px;
      &.arrow-left {
        margin: 0 10px 0 0;
      }
    }
  }
  section.title {
    @extend .second-font;
    margin-top: 25px;
    @media (min-width: $screen-sm-min) {
      margin-top: 38px;
    }
    h1 {
      font-weight: bold;
      margin-top: 0;
      @media (min-width: $screen-sm-min) {
        font-size: 50px;
      }
    }
    h3, span.red {
      font-weight: bold;
      color: $brand-primary;
      margin: 10px 0 25px;
      font-size: 24px;
      display: block;
      @media (min-width: $screen-sm-min) {
        margin: 10px 0 40px;
      }
    }
    .back-btn {
      color: $text-color;
      font-family: $font-family-sans-serif;
      font-size: 12px;
      line-height: 12px;
      img {
        width: 6px;
        margin-right: 4px;
      }
      > * {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
  section.featured-details {
    color: $grey;
    .container {
      &:before {
        content: '';
        border-bottom: 1px solid $gray-lighter;
        display: block;
        margin-bottom: 25px;
        @media (min-width: $screen-sm-min) {
          margin-bottom: 35px;
        }
      }
      &:after {
        content: '';
        border-bottom: 1px solid $gray-lighter;
        display: block;
        margin-top: 5px;
        @media (min-width: $screen-sm-min) {
          margin-top: 35px;
        }
      }
    }
    .price {
      color: $brand-primary;
      font-weight: bold;
      font-size: 26px;
      @extend .primary-font-bold;
    }
    .featureds {
      li {
        margin: 0 0 15px;
        @media (min-width: $screen-sm-min) {
          margin: 0 6px 0 0;
        }
        @media (min-width: $screen-md-min) {
          margin: 0 30px 0 0;
        }
        &.cex {
          margin: 0;
        }
      }
      img {
        height: 29px;
        @media (min-width: $screen-sm-min) {
          margin-right: 6px;
        }
      }
    }
    [class*="col-"] {
      text-align: center;
      margin-bottom: 15px;
      @media (min-width: $screen-sm-min) {
        text-align: left;
        margin: 0;
      }
      &.code-container {
        @media (min-width: $screen-sm-min) {
          text-align: right;
        }
        div {
          cursor: pointer;
          @media (min-width: $screen-sm-min) {
            display: block;
          }
          @media (min-width: $screen-md-min) {
            display: inline-block;
            margin-left: 40px;
          }
        }
      }
    }
  }
  section.description {
    font-size: 16px;
    margin: 30px 0;
    @media (min-width: $screen-sm-min) {
      margin: 50px 0 30px;
    }
    .row {
      > div {
        &:first-child {
          margin-bottom: 30px;
          @media (min-width: $screen-sm-min) {
            margin-bottom: 0;
          }
        }
      }
    }
    p {
      margin-bottom: 20px;
      @media (min-width: $screen-sm-min) {
        margin-right: 40px;
      }
      @media (min-width: $screen-md-min) {
        margin-right: 100px;
      }
    }
    h3 {
      @extend .second-font;
      font-weight: bold;
      text-transform: uppercase;
      margin-top: 0;
      margin-bottom: 30px;
      &.h4 {
        font-family: 'Metropolis-Bold', sans-serif;
        margin-top: 6px;
        font-weight: 500;
        line-height: 1.1;
        color: inherit;
        font-size: 18px;
        text-transform: none;
      }
    }
    h4 {
      @extend .primary-font-bold;
      margin-top: 0;
      margin-bottom: 30px;
      @media (min-width: $screen-sm-min) {
        margin-top: 6px;
      }
    }
    button {
      @media (min-width: $screen-sm-min) {
        width: 330px;
      }
      white-space: normal;
      text-align: left;
      text-transform: none;
    }
  }
  section.map {
    margin-top: 28px;
    #map {
      width: 100%;
      height: 260px;
      @media (min-width: $screen-sm-min) {
        height: 400px;
      }
    }
  }
  .modal-lg {
    @media (min-width: $screen-sm-min) {
      width: calc(100% - 60px);
      max-width: 1150px;
    }
  }
  section.detail-post {
    h3 {
      text-align: left;
    }
    .dotdotdot {
      height: 100px;
      overflow: hidden;
      margin-bottom: 50px;
    }
    .container {
      &:after {
        content: '';
        border-bottom: 1px solid $gray-lighter;
        display: block;
      }
    }
    a {
      @media (min-width: $screen-md-min) {
        margin-bottom: 30px;
      }
    }
  }
}
