
// Swiper
.swiper-pagination-bullet-active {
  background-color: $brand-primary;
}

.swiper-button-next {
  background-image: url(#{$img-path}/swiper-button-next.png);
}

.swiper-button-prev {
  background-image: url(#{$img-path}/swiper-button-prev.png);
}
