
// Modal
.modal {
  z-index: 9999;
  .modal-header {
    border: none;
  }
  .modal-content {
    padding: 15px;
  }
  .modal-title {
    @extend .second-font;
    color: $brand-primary;
    font-size: 30px;
    line-height: 32px;
  }
  .modal-footer {
    text-align: left;
  }
  .close {
    span {
      font-size: 24px;
    }
  }
  &#videoModal {
    .modal-body {
      padding: 0;
      iframe {
        width: 100%;
        height: 400px;
        @media (min-width: $screen-md-min) {
          height: 480px;
        }
        @media (min-width: $screen-md-min) {
          height: 540px;
        }
      }
    }
  }
  &#galleryModal {
    .modal-header {
      padding: 0;
      @media (min-width: $screen-sm-min) {
        padding: 15px;
      }
      h3 {
        display: none;
        @media (min-width: $screen-sm-min) {
          display: block;
        }
      }
    }
    .modal-content {
      max-height: 100vh;
    }
    .modal-body {
      padding: 0;
    }
    .swiper-gallery-container {
      @extend .swiper-container;
      .swiper-slide {
        width: 100%;
        height: 220px;
        max-height: 100vh;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        @extend .watermark;
        @media screen and (orientation:landscape) {
          height: calc(100vh - 80px);
        }
        @media (min-width: $screen-sm-min) {
          height: 400px;
        }
        @media (min-width: $screen-md-min) {
          height: auto;
        }
        @media (min-width: $screen-lg-min) {
          height: auto;
        }
      }
    }
  }
}
