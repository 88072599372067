
// Buildings list
section.list {
  padding-top: 60px;
  @media (min-width: $screen-sm-min) {
    margin-bottom: 100px;
  }
  @media (min-width: $screen-md-min) {
    padding-top: 145px;
  }
  .message {
    margin: 40px;
    color: $brand-primary;
    text-align: center;
  }
}
