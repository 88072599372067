
// Utils
.form-control {
  @media (min-width: $screen-md-min) {
    height: $input-height-large;
  }
}

.primary-font-semibold {
  font-family: 'Metropolis-SemiBold', sans-serif;
}

.primary-font-bold {
  font-family: 'Metropolis-Bold', sans-serif;
}

.primary-font-medium {
  font-family: 'Metropolis-Medium', sans-serif;
}

.primary-font-extra-light {
  font-family: 'Metropolis-ExtraLight', sans-serif;
}

.second-font {
  font-family: 'Playfair Display', serif;
}

.btn-primary {
  @extend .second-font;
  font-weight: bold;
  text-transform: uppercase;
  &:hover, &:focus {
    border-color: #480016;
  }
}

.btn-lg {
  font-size: 16px;
  padding: 9px 27px;
}

.bottom-line {
  &:after {
    content: '';
    position: absolute;
    height: 1px;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $white;
    margin-top: 2px;
    @media (min-width: $screen-md-min) {
      bottom: 28px;
    }
  }
}

.font-awesome {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 20px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

.icon-arrow-down {
  &:after {
    content: "";
    @extend .font-awesome;
  }
}

.icon-checkbox {
  &:after {
    content: "\f00c";
    @extend .font-awesome;
    font-size: 13px;
  }
}

.icon-square {
  display: inline-block;
  width: 12px;
  height: 12px;
  border: 1px solid $text-color;
}

.center-container {
  display: table;
  width: 100%;
  height: 100%;
  text-align: center;
  > .center {
    display: table-cell;
    vertical-align: middle;
  }
}

.section-title {
  @extend .second-font;
  font-weight: bold;
  font-size: 24px;
  text-transform: uppercase;
  text-align: center;
  padding: 15px 0;
  margin: 0;
  @media (min-width: $screen-sm-min) {
    padding: 55px 0 30px;
  }
}

.icon-arrow-right-big {
  position: relative;
  &:after {
    content: url(#{$img-path}/icon-arrow-right-big.png);
    position: absolute;
    top: 0;
    right: 10px;
    top: 50%;
    margin-top: -13px;
  }
}

.watermark {
  &:after {
    content: url(#{$img-path}/watermark.png);
    opacity: 0.2;
    position: absolute;
    left: 50%;
    top: 50%;
    @include translate(-50%, -50%);
    z-index: 1;
    pointer-events: none;
  }
}

.social-btn, .play-btn {
  width: 32px;
  height: 32px;
  cursor: pointer;
  &:hover {
    path {
      fill: $brand-primary;
    }
  }
  path {
    fill: #606060;
  }
}

.thumbs-swiper {
  .swiper-gallery-thumbs-container {
    @extend .swiper-container;
    .swiper-slide {
      width: 100%;
      height: 300px;
      background-position: center;
      background-size: cover;
      @media (min-width: $screen-sm-min) {
        height: 350px;
      }
      &:hover {
        &:after {
          content: url(#{$img-path}/icon-plus-big.png);
          position: absolute;
          top: 50%;
          left: 50%;
          @include translate(-50%, -50%);
          width: 44px;
          height: 44px;
        }
      }
    }
  }
}

section.concept-boutique {
  @extend .second-font;
  position: relative;
  font-weight: bold;
  text-align: center;
  color: $white;
  background-image: url(#{$img-path}/concept-boutique.jpg);
  background-size: cover;
  background-position: center;
  padding: 60px 0;
  @media (min-width: $screen-sm-min) {
    padding: 110px 0;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.25);
    z-index: 1;
  }
  h2 {
    font-size: 26px;
    margin: 0 0 45px;
    @media (min-width: $screen-sm-min) {
      font-size: 50px;
    }
  }
  h4 {
    font-size: 18px;
    margin-top: 0;
    @media (min-width: $screen-sm-min) {
      font-size: 24px;
    }
  }
  .content {
    position: relative;
    max-width: 740px;
    margin: 0 auto;
    z-index: 2;
  }
}

[data-oembed-url] {
  margin: 10px 0 20px;
  > div {
    max-width: 100% !important;
  }
}

.video-iframe {
  position: relative;
  padding-bottom: 62%;
  margin: 0 auto;
  @media (min-width: $screen-sm-min) {
    padding-bottom: 50%;
    max-width: 700px;
  }
  @media (min-width: $screen-md-min) {
    padding-bottom: 0;
    height: 440px;
  }
  iframe {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .video-grid & {
    width: 100%;
  }
}
