
// Events
section.events {
  @media (min-width: $screen-sm-min) {
    margin-bottom: 100px;
  }
  section.logos {
    margin: 20px 0 0;
    @media (min-width: $screen-md-min) {
      margin: 60px 0 40px
    }
    [class*='col-'] {
      text-align: center;
      margin-bottom: 20px;
    }
  }
  section.posts {
    [class*="col-"] {
      margin-bottom: 20px;
      @media (min-width: $screen-md-min) {
        margin-bottom: 30px;
      }
    }
  }
  section.form {
    background-color: $brand-primary;
    color: $white;
    text-align: center;
    padding: 20px 0;
    @media (min-width: $screen-md-min) {
      padding: 40px 0;
    }
    h2 {
      @extend .primary-font-semibold;
      font-size: 24px;
      max-width: 700px;
      margin: 0 auto 20px;
      @media (min-width: $screen-md-min) {
        font-size: 34px;
        margin: 0 auto 35px;
      }
    }
    button {
      border-color: $white;
    }
  }
}