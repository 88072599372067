
// Quotes
.quotes {
  .text {
    @extend .primary-font-semibold;
    font-size: 20px;
    line-height: 26px;
    margin: 30px 0 55px;
    @media (min-width: $screen-sm-min) {
      font-size: 24px;
      line-height: 30px;
    }
  }
  .name {
    @extend .second-font;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .position {
    color: $grey;
  }
  .swiper-slide {
    margin-bottom: 60px;
    padding: 15px;
  }
}