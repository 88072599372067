
// Form
.ajax-message {
  display: none;
}
#sending {
  display: none;
  padding-left: 20px;
  i {
    font-size: 15px;
  }
}

// Form Newsletter
#modalNewsletter {
  .modal-dialog {
    background-color: white;
    margin-top: 100px;
  }
}