
// About us
section.about-us {
  @media (min-width: $screen-sm-min) {
    margin-bottom: 100px;
  }
  section.phrases {
    > .container {
      text-align: center;
      padding-bottom: 20px;
      @media (min-width: $screen-sm-min) {
        padding-bottom: 30px;
      }
      &:before {
        content: '';
        border-top: 1px solid $gray-lighter;
        display: block;
        padding-top: 20px;
        @media (min-width: $screen-sm-min) {
          padding-top: 30px;
        }
      }
      img {
        width: 60px;
      }
      h2 {
        @extend .second-font;
        color: $brand-primary;
        display: inline-block;
        vertical-align: middle;
        margin-left: 15px;
        margin-top: 0;
        margin-bottom: 0;
      }
    }
    .phrases-container {
      background-color: $bg-primary;
      font-size: 0;
      padding: 20px 0 0;
      @media (min-width: $screen-sm-min) {
        padding: 55px 0 25px;
      }
      strong {
        @extend .primary-font-bold;
        color: $brand-primary;
      }
      [class*="col-"] {
        font-size: 16px;
        margin-bottom: 20px;
        @media (min-width: $screen-md-min) {
          float: none;
          display: inline-block;
          vertical-align: top;
        }
        p {
          @media (min-width: $screen-md-min) {
            width: 90%;
          }
        }
      }
    }
  }
  section.video {
    text-align: center;
    margin: 20px 0;
    @media (min-width: $screen-sm-min) {
      margin: 40px 0;
    }
  }
  section.members {
    .section-title {
      border-top: 1px solid $gray-lighter;
    }
    .row {
      font-size: 0;
    }
    [class*="col-"] {
      float: none;
      display: inline-block;
      vertical-align: top;
    }
    .member {
      text-align: center;
      margin-bottom: 20px;
      @media (min-width: $screen-sm-min) {
        margin-bottom: 45px;
      }
    }
    .name {
      @extend .second-font;
      font-size: 26px;
      font-weight: bold;
    }
    .title {
      @extend .second-font;
      font-size: 18px;
      color: $brand-primary;
    }
    .text {
      width: 90%;
      margin: 20px auto 0;
      font-size: $font-size-base;
    }
    img {
      margin-bottom: 20px;
    }
  }
  section.quotes {
    text-align: center;
    background-color: $bg-primary;
    padding-bottom: 20px;
    img {
      display: none;
    }
    .text {
      text-align: center;
      color: $brand-primary;
      max-width: 700px;
      margin: 25px auto 30px;
    }
    .swiper-slide {
      margin-bottom: 30px;
      @media (min-width: $screen-sm-min) {
        margin-bottom: 40px;
      }
    }
  }
  section.us-img {
    height: 260px;
    background-image: url(#{$img-path}/about-us.jpg);
    background-size: cover;
    background-position: center;
    @media (min-width: $screen-sm-min) {
      height: 489px;
    }
  }
  section.form {
    margin: 20px 0;
    @media (min-width: $screen-sm-min) {
      margin: 50px 0 0;
    }
    h2 {
      @extend .primary-font-bold;
      margin-top: 0;
    }
  }
}
