
// Blog
section.blog {
  @media (min-width: $screen-sm-min) {
    margin-bottom: 100px;
  }
  h1 {
    @extend .second-font;
    text-align: center;
    margin: 70px 0 20px;
    color: $brand-primary;
    font-size: 26px;
    @media (min-width: $screen-md-min) {
      margin: 120px 0 50px;
      font-size: 50px;
    }
  }
  section.posts {
    [class*="col-"] {
      margin-bottom: 20px;
      @media (min-width: $screen-md-min) {
        margin-bottom: 30px;
      }
    }
  }
}

.post-grid {
  background-color: $bg-primary;
  .title {
    @extend .second-font;
    display: block;
    font-weight: bold;
    height: 52px;
    overflow: hidden;
    font-size: 24px;
    color: $text-color;
    margin: 20px 0 15px;
    line-height: 26px;
  }
  .date {
    margin-bottom: 10px;
    color: $brand-primary;
  }
  .content {
    padding: 15px;
  }
  .img {
    width: 100%;
    height: 200px;
    background-size: cover;
    background-position: center;
    @media (min-width: $screen-lg-min) {
      height: 230px;
    }
  }
  .text {
    color: $grey;
    height: 62px;
    overflow: hidden;
  }
  footer {
    border-top: 1px solid $grey;
    margin-top: 40px;
    padding-top: 25px;
    .btn-group {
      float: right;
    }
  }
}