
// Post
section.post {
  @media (min-width: $screen-sm-min) {
    margin-bottom: 100px;
  }
  section.title {
    h1 {
      @extend .second-font;
      text-align: center;
      font-size: 26px;
      @media (min-width: $screen-md-min) {
        font-size: 50px;
      }
    }
    .container {
      &:after {
        content: '';
        border-bottom: 1px solid $gray-lighter;
        display: block;
        margin-top: 30px;
      }
    }
    a {
      display: inline-block;
      margin-top: 20px;
      img {
        width: 8px;
      }
      span {
        margin-left: 5px;
      }
    }
  }
}