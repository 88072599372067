
// Path
$img-path: '../../img';
$fonts-path: '../../fonts';

// Transitions
$transition-time:     0.2s;
$transition-time-lg:  0.4s;

// Fontawesome
$fa-font-path: "../../fonts/font-awesome";

// Colors
$white: #FFF;
$black: #000;
$grey:  #555;

$brand-primary: #900021;
$bg-primary:    #f3f3f3;

$paginator-color: #cececd;

// Scaffolding
$text-color: #202020;

// Typography
$font-family-sans-serif: 'Metropolis-Regular', sans-serif;

// Components
$border-radius-base: 0;
$border-radius-large: 0;
$padding-base-horizontal: 20px;

// Navbar
$navbar-height:                      46px;
$navbar-height-desktop:              80px;
$navbar-default-bg:                  $black;
$navbar-default-toggle-icon-bar-bg:  $white;
$navbar-default-toggle-hover-bg:     none;
$navbar-default-toggle-border-color: none;
$navbar-default-link-color:          $white;
$navbar-default-link-active-color:   $white;
$navbar-default-link-hover-color:    $white;

// Grid system
$grid-float-breakpoint: 992px;

// Forms
$input-bg:                  transparent;
$input-color:               $text-color;
$input-border:              $text-color;
$input-border-focus:        $text-color;
$input-color-placeholder:   $text-color;
$input-height-base:         30px;
$input-height-large:        40px;

// Buttons
$btn-primary-border: $brand-primary;
