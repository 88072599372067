
// Base rules
html {
  background-color: $black;
}

body {
  overflow-x: hidden;
}
h2 {
  &.h2-seo {
    font-size: inherit;
    line-height: inherit;
    margin: inherit;
    display: inline;
  }
}
a {
  @include transition(all $transition-time);
  &:hover, &:focus {
    outline: none;
    text-decoration: none;
  }
}

ul {
  @extend .list-unstyled;
  margin: 0;
}

textarea {
  resize: vertical;
}

img {
  max-width: 100%;
  height: auto;
}

iframe {
  border: none;
}

input, textarea {
  box-shadow: none !important;
  &:focus {
    outline: none !important;
  }
}

button {
  &:focus {
    outline: none !important;
  }
}

select {
  &.select2 {
    display: none;
  }
}

@media (max-width: 350px){
  footer#main-footer {
    font-size: 10px;
  }
}