
// Default page
section.living-luxury {
  @media (min-width: $screen-sm-min) {
    margin-bottom: 100px;
  }
  &.category {
    header.page {
      h1 {
        margin: 0;
      }
      .icon {
        margin-bottom: 0;
        @media (min-width: $screen-sm-min) {
          font-size: 50px;
        }
        @media (min-width: $screen-sm-min) {
          margin-top: 70px;
        }
      }
    }
    .section-title {
      border-top: 1px solid $gray-lighter;
    }
    section.video {
      .container {
        &:before {
          content: '';
          border-top: 1px solid $gray-lighter;
          display: block;
          padding-top: 20px;
          @media (min-width: $screen-sm-min) {
            padding-top: 40px;
          }
        }
      }
    }
    section.categories {
      .row {
        > div {
          @media (min-width: $screen-lg-min) {
            width: calc(100% / 6);
          }
          h3 {
            @media (min-width: $screen-lg-min) {
              font-size: 16px;
            }
          }
          .category {
            @media (min-width: $screen-lg-min) {
              padding: 25px 35px;
            }
          }
          svg {
            @media (min-width: $screen-lg-min) {
              max-width: 50px;
            }
          }
        }
      }
    }
  }
  section.gallery {
    position: relative;
    .swiper-slide {
      background-size: cover;
      background-position: center;
      height: 500px;
      @media (min-width: $screen-sm-min) {
        height: 500px;
      }
    }
    .text {
      position: absolute;
      bottom: 40px;
      left: 0;
      right: 0;
      margin: 0 auto;
      padding: 20px;
      color: $white;
      background-color: $brand-primary;
      max-width: 545px;
      text-align: center;
      p {
        margin: 0;
      }
    }
    .primary-color-box {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      background-color: $brand-primary;
      max-width: 545px;
      height: 40px;
      z-index: 1;
    }
    .swiper-pagination-bullet {
      background-color: $white;
      opacity: 1;
    }
    .swiper-pagination-bullet-active {
      background-color: $black;
      opacity: .2;
    }
  }
  section.video {
    margin: 20px 0;
    @media (min-width: $screen-sm-min) {
      margin: 50px 0;
    }
  }
  section.services {
    .container {
      &:before {
        content: '';
        border-bottom: 1px solid $gray-lighter;
        display: block;
      }
    }
  }
}
