
// Collaborators
section.collaborators {
  @media (min-width: $screen-sm-min) {
    margin-bottom: 100px;
  }
  section.collaborators-info {
    .info {
      text-align: center;
      background-color: $bg-primary;
      padding: 20px 0;
      @media (min-width: $screen-md-min) {
        padding: 40px 0;
      }
      iframe {
        width: 100%;
        height: 260px;
        @media (min-width: $screen-md-min) {
          height: 400px;
          width: 95%;
        }
        @media (min-width: $screen-lg-min) {
          height: 455px;
        }
      }
    }
    .video {
      text-align: center;
    }
    .name {
      @extend .second-font;
      font-size: 26px;
      font-weight: bold;
      margin-top: 20px;
      @media (min-width: $screen-md-min) {
        margin-top: 50px;
      }
    }
    .title {
      @extend .second-font;
      font-size: 18px;
      color: $brand-primary;
    }
    .text {
      width: 90%;
      margin: 20px auto;
    }
  }
  .thumbs-swiper {
    margin-bottom: 20px;
    @media (min-width: $screen-md-min) {
      margin-bottom: 40px;
    }
  }
  #galleryModal {
    .watermark {
      &:after {
        display: none;
      }
    }
  }
}