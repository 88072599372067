
// Contact
section.contact {
  padding-top:80px;
  @media (min-width: $screen-sm-min) {
    margin-bottom: 0px;
  }
  section.map {
    margin-top: 0px;
    #map {
      width: 100%;
      height: 260px;
      @media (min-width: $screen-sm-min) {
        height: 600px;
      }
    }
  }
  section.icons {
    background-color: $bg-primary;
    padding: 30px 0 0;
    @media (min-width: $screen-sm-min) {
      padding: 65px 0 35px;
    }
    .col-md-10 {
      .row {
        font-size: 0;
      }
      [class*="col-"] {
        float: none;
        display: inline-block;
        vertical-align: top;
        font-size: $font-size-base;
        margin-bottom: 30px;
        @media (min-width: $screen-md-min) {
          margin-bottom: 50px;
        }
      }
    }
    figure {
      float: left;
      margin-right: 20px;
      width: 55px;
      text-align: center;
    }
    p {
      float: left;
      width: calc(100% - 81px);
      padding-right: 20px;
      margin-bottom: 0;
    }
  }
  section.form {
    font-size: 16px;
    margin: 20px 0;
    @media (min-width: $screen-sm-min) {
      margin: 50px 0;
    }
    h1 {
      @extend .second-font;
      font-size: 26px;
      font-weight: bold;
      margin: 0 0 5px;
      @media (min-width: $screen-sm-min) {
        font-size: 50px;
      }
    }
    h2 {
      @extend .second-font;
      font-size: 24px;
      font-weight: bold;
      color: $brand-primary;
      margin-top: 0;
    }
    address {
      margin: 50px 0 35px;
      width: 50%;
      p {
        &:first-of-type {
          @extend .primary-font-bold;
          margin: 0;
        }
      }
    }
    .email {
      margin-bottom: 20px;
    }
  }
}