
// Service
section.service {
  @media (min-width: $screen-sm-min) {
    margin-bottom: 100px;
  }
  &.investments, &.interior-design {
    .section-title {
      border-top: 1px solid $gray-lighter;
    }
    section.buildings {
      margin-bottom: auto;
      .container {
        &:after {
          content: '';
          border-bottom: 1px solid $gray-lighter;
          display: block;
        }
      }
    }
  }
  &.ancient-income, &.new-construction {
    section.text {
      background-color: $bg-primary;
    }
    section.form {
      h2 {
        @extend .primary-font-bold;
        font-size: 36px;
        margin-top: 0;
      }
    }
  }
  &.ancient-income {
    section.form {
      padding: 20px 0;
      @media (min-width: $screen-sm-min) {
        padding: 45px 0;
      }
    }
  }
  &.new-construction {
    section.form-buildings {
      margin-top: 20px;
      @media (min-width: $screen-sm-min) {
        margin-top: 45px;
      }
    }
    section.form {
      margin-bottom: 20px;
      h2 {
        margin-bottom: 20px;
        @media (min-width: $screen-sm-min) {
          margin-bottom: 45px;
        }
      }
    }
  }
  section.tabs {
    @extend .second-font;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 15px;
    @media (min-width: $screen-sm-min) {
      font-size: 24px;
    }
    li {
      width: 50%;
      @media (min-width: $screen-sm-min) {
        width: calc(50% - 8px);
      }
      &:first-child {
        @media (min-width: $screen-sm-min) {
          margin-right: 16px;
        }
      }
      &.active {
        a {
          color: $white;
          background-color: $brand-primary;
        }
      }
    }
    a {
      color: $text-color;
      border: 1px solid $bg-primary;
      &:hover {
        border-color: $bg-primary;
      }
    }
    .nav-tabs {
      border: none;
    }
  }
  section.icons {
    background-color: $bg-primary;
    padding: 30px 0 0;
    @media (min-width: $screen-sm-min) {
      padding: 65px 0 35px;
    }
    .col-md-10 {
      .row {
        font-size: 0;
      }
      [class*="col-"] {
        float: none;
        display: inline-block;
        vertical-align: top;
        font-size: $font-size-base;
        margin-bottom: 30px;
        @media (min-width: $screen-md-min) {
          margin-bottom: 50px;
        }
      }
    }
    figure {
      float: left;
      margin-right: 20px;
      width: 55px;
      text-align: center;
    }
    p {
      float: left;
      width: calc(100% - 81px);
      padding-right: 20px;
      margin-bottom: 0;
    }
  }
  section.video {
    margin-top: 20px;
    @media (min-width: $screen-sm-min) {
      margin-top: 40px;
    }
  }
  section.video-grid {
    color: $white;
    margin-top: 20px;
    @media (min-width: $screen-sm-min) {
      margin-top: 40px;
    }
    .container {
      &:after {
        content: '';
        border-bottom: 1px solid $gray-lighter;
        display: block;
        margin-top: 40px;
      }
    }
    .content {
      padding: 40px 35px;
      background-color: $brand-primary;
      margin-bottom: -99999px;
      padding-bottom: 99999px;
    }
    h2 {
      font-size: 36px;
      @extend .second-font;
      font-weight: bold;
      margin: 0 0 50px;
    }
    p {
      font-size: 16px;
      margin-bottom: 30px;
    }
    button {
      white-space: normal;
      border: 1px solid $white;
      font-size: 16px;
      margin-bottom: 45px;
    }
    .row {
      overflow: hidden;
    }
    [class*="col-"] {
      margin-bottom: -99999px;
      padding-bottom: 99999px;
    }
    .video-iframe {
      @media (min-width: $screen-md-min) {
        float: left;
      }
    }
  }
}