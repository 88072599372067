
// Select 2
.select2-container {
  width: 100% !important;
  outline: none;
  .count {
    position: absolute;
    font-size: 10px;
    top: 7px;
    right: 26px;
    padding: 3px 0 2px;
    background-color: $bg-primary;
    border: 1px solid $text-color;
    line-height: 12px;
    border-radius: 50%;
    width: 21px;
    text-align: center;
    &:before {
      content: '+';
    }
    @media (min-width: $screen-md-min) {
      top: 11px;
    }
  }
}

.select2-selection {
  height: $input-height-base !important;
  border-radius: $border-radius-base !important;
  border-color: $text-color !important;
  background-color: transparent !important;
  cursor: pointer !important;
  @media (min-width: $screen-md-min) {
    height: $input-height-large !important;
  }
}

.select2-selection__placeholder {
  @extend .second-font;
  color: $text-color !important;
  text-transform: uppercase;
}

.select2-dropdown {
  @extend .second-font;
  border-color: $text-color !important;
  border-radius: $border-radius-base !important;
  z-index: 9 !important;
}

.select2-search__field {
  border-color: $text-color !important;
  cursor: pointer !important;
  @extend .second-font;
  color: $text-color !important;
  padding-left: 0 !important;
  text-transform: uppercase;
  @include placeholder($text-color);
  @media (min-width: $screen-md-min) {
    margin-top: 0px !important;
  }
}

.select2-selection__rendered {
  @extend .second-font;
  outline: none;
  height: $input-height-base !important;
  padding-left: 8px !important;
  padding-right: 20px !important;
  @media (min-width: $screen-md-min) {
    height: $input-height-large !important;
    line-height: 40px !important;
    padding-left: 20px !important;
  }
}

.select2-results__option--highlighted[aria-selected] {
  color: $text-color !important;
  background-color: $bg-primary !important;
}

.select2-selection--single {
  outline: none;
}

.select2-selection__arrow {
  @extend .icon-arrow-down;
  &:after {
    margin-top: 4px;
    @media (min-width: $screen-md-min) {
      margin-top: 10px;
    }
  }
  b {
    display: none;
  }
}

.select2-selection--multiple {
  @extend .icon-arrow-down;
  &:after {
    position: absolute;
    right: 8px;
    top: 6px;
    @media (min-width: $screen-md-min) {
      top: 11px;
    }
  }
  .select2-selection__choice {
    padding: 0 2px 0 0 !important;
    @media (min-width: $screen-md-min) {
      margin-top: 0 !important;
    }
  }
  .select2-selection__rendered {
    padding-right: 0 !important;
    width: calc(100% - 50px) !important;
  }
}

.select2-selection__clear {
  margin: 0 5px 0 0 !important;
}

.select2-selection__choice {
  background-color: transparent !important;
  border: none !important;
}

.select2-results__option[aria-selected=true] {
  background-color: $bg-primary !important;
}

[aria-multiselectable="true"] {
  .select2-results__option[role="treeitem"] {
    position: relative;
    padding-right: 20px;
    &.select2-results__message {
      &:before {
        display: none;
      }
    }
    &:before {
      content: "";
      position: absolute;
      top: 10px;
      right: 15px;
      @extend .icon-square;
    }
    &[aria-selected="true"] {
      color: $text-color !important;
      background-color: transparent !important;
      @extend .icon-checkbox;
      &:after {
        position: absolute;
        top: 10px;
        right: 14px;
      }
      &:hover {
        background-color: $bg-primary !important;
      }
    }
    &.select2-results__message {
      &:after {
        content: '';
      }
    }
  }
}

.select2-selection__choice__remove, .select2-selection__clear {
  display: none !important;
}

#select2-property-select-results {
  li:first-child {
    text-transform: uppercase;
    &:before, &.after {
      display: none;
    }
  }
}