
// Cookies
#cookies-bar {
  position: fixed;
  display: none;
  bottom: 0;
  background: $black;
  width: 100%;
  z-index: 99;
  .container {
    position: relative;
  }
  .ok {
    position: relative;
    color: $white;
    border: 1px solid $white;
    padding: 8px 20px;
    margin-bottom: 10px;
    &:hover {
      color: darken($white, 13%);
      border: 1px solid darken($white, 13%);
    }
    @media (min-width: $screen-sm-min) {
      position: absolute;
      right: 15px;
      top: 15px;
      margin-bottom: 0;
    }
  }
  a {
    display: inline-block;
    cursor: pointer;
  }
  p {
    font-size: 13px;
    margin: 15px 0;
    @media (min-width: $screen-sm-min) {
      width: 72%;
    }
    a {
      text-decoration: underline;
      text-transform: lowercase;
      &:hover {
        border: none;
      }
    }
  }
}
