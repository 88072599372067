
// Font Awesome
@import "node_modules/font-awesome/scss/font-awesome";

// Google Fonts
@import url('https://fonts.googleapis.com/css?family=Playfair+Display:400,700');

// Fonts
@font-face {
  font-family: 'Metropolis-Regular';
  src: url('#{$fonts-path}/Metropolis-Regular.eot?#iefix') format('embedded-opentype'),  url('#{$fonts-path}/Metropolis-Regular.otf')  format('opentype'),
       url('#{$fonts-path}/Metropolis-Regular.woff') format('woff'), url('#{$fonts-path}/Metropolis-Regular.ttf')  format('truetype'), url('#{$fonts-path}/Metropolis-Regular.svg#Metropolis-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Metropolis-Bold';
  src: url('#{$fonts-path}/Metropolis-Bold.eot?#iefix') format('embedded-opentype'),  url('#{$fonts-path}/Metropolis-Bold.otf')  format('opentype'),
       url('#{$fonts-path}/Metropolis-Bold.woff') format('woff'), url('#{$fonts-path}/Metropolis-Bold.ttf')  format('truetype'), url('#{$fonts-path}/Metropolis-Bold.svg#Metropolis-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Metropolis-Medium';
  src: url('#{$fonts-path}/Metropolis-Medium.eot?#iefix') format('embedded-opentype'),  url('#{$fonts-path}/Metropolis-Medium.otf')  format('opentype'),
       url('#{$fonts-path}/Metropolis-Medium.woff') format('woff'), url('#{$fonts-path}/Metropolis-Medium.ttf')  format('truetype'), url('#{$fonts-path}/Metropolis-Medium.svg#Metropolis-Medium') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Metropolis-SemiBold';
  src: url('#{$fonts-path}/Metropolis-SemiBold.eot?#iefix') format('embedded-opentype'),  url('#{$fonts-path}/Metropolis-SemiBold.otf')  format('opentype'),
       url('#{$fonts-path}/Metropolis-SemiBold.woff') format('woff'), url('#{$fonts-path}/Metropolis-SemiBold.ttf')  format('truetype'), url('#{$fonts-path}/Metropolis-SemiBold.svg#Metropolis-SemiBold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Metropolis-ExtraLight';
  src: url('#{$fonts-path}/Metropolis-ExtraLight.eot?#iefix') format('embedded-opentype'),  url('#{$fonts-path}/Metropolis-ExtraLight.otf')  format('opentype'),
       url('#{$fonts-path}/Metropolis-ExtraLight.woff') format('woff'), url('#{$fonts-path}/Metropolis-ExtraLight.ttf')  format('truetype'), url('#{$fonts-path}/Metropolis-ExtraLight.svg#Metropolis-ExtraLight') format('svg');
  font-weight: normal;
  font-style: normal;
}
