
// Layout
header#main-header {
  position: relative;
  z-index: 99;
  margin-top: $navbar-height;
  @media (min-width: $screen-md-min) {
    margin-top: $navbar-height-desktop;
  }
  .navbar {
    @media (min-width: $screen-md-min) {
      min-height: $navbar-height-desktop;
    }
    ul {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  .navbar-left {
    @extend .second-font;
    font-size: 17px;
    text-transform: uppercase;
    @media (min-width: $screen-md-min) {
      display: table;
      width: calc(100% - 291px);
    }
    > li {
      @media (min-width: $screen-md-min) {
        width: calc(100% / 5);
        text-align: center;
        display: table-cell;
        vertical-align: middle;
        float: none;
      }
      &:hover {
        .dropdown-menu {
          @media (min-width: $screen-md-min) {
            display: block;
          }
        }
      }
      &.active {
        > a {
          @extend .bottom-line;
        }
      }
      > a {
        position: relative;
        display: inline-block;
        &:hover {
          @extend .bottom-line;
        }
        @media (min-width: $screen-md-min) {
          padding: 30px 0;
        }
      }
      .dropdown-menu {
        color: $white;
        background-color: $black;
        .container {
          @media (min-width: $screen-md-min) {
            width: 370px;
          }
        }
        a {
          color: $white;
          &:hover {
            border-bottom: 1px solid $white;
          }
        }
        .featureds {
          font-weight: bold;
          @media (min-width: $screen-md-min) {
            border-right: 1px solid $white;
          }
          li {
            @media (min-width: $screen-md-min) {
              margin-bottom: 20px;
            }
          }
          a {
            @media (min-width: $screen-md-min) {
              font-size: 18px;
            }
          }
        }
        li {
          text-transform: initial;
          margin-bottom: 5px;
          font-size: 16px;
          &.active {
            > a {
              border-bottom: 1px solid $white;
            }
          }
          @media (min-width: $screen-md-min) {
            margin-bottom: 15px;
          }
        }
      }
    }
  }
  .navbar-right {
    @media (min-width: $screen-md-min) {
      width: 160px;
      margin-right: 0;
      padding: 30px 0;
    }
    > li {
      @media (min-width: $screen-md-min) {
        float: none;
        display: inline-block;
        vertical-align: middle;
      }
      &:nth-child(1) {
        .form-group {
          position: relative;
          padding: 10px 15px;
          margin: 0;
          @media (min-width: $screen-md-min) {
            padding: 0;
          }
          input {
            border: 1px solid $white;
            color: $white;
            @include placeholder($white);
            @media (min-width: $screen-md-min) {
              display: none;
              position: absolute;
              top: 2px;
              border: none;
              border-bottom: 1px solid $white;
              padding-bottom: 0;
              padding-left: 2px;
              padding-top: 0;
              width: 620px;
              left: -630px;
              height: 20px;
            }
            @media (min-width: $screen-lg-min) {
              width: 820px;
              left: -830px;
            }
          }
          img {
            position: absolute;
            cursor: pointer;
            top: 18px;
            right: 24px;
            width: 15px;
            height: 15px;
            @media (min-width: $screen-md-min) {
              position: relative;
              top: inherit;
              right: inherit;
            }
          }
        }
      }
      &:nth-child(2) {
        @media (min-width: $screen-md-min) {
          width: 140px;
          text-align: right;
        }
      }
      .dropdown {
        &.access {
          display: none;
          .dropdown-menu {
            top: 57px;
            margin: 0;
          }
          .message {
            display: none;
            font-size: 12px;
            margin-top: 10px;
          }
        }
        &.languages {
          li {
            a {
              color: $white;
              @media (min-width: $screen-md-min) {
                color: $text-color;
              }
            }
          }
        }
        &.open {
          > a {
            background-color: transparent !important;
          }
        }
        > a {
          display: block;
          color: $white;
          padding: 10px 15px;
          &:focus {
            background-color: transparent;
          }
          @media (min-width: $screen-md-min) {
            padding: 0;
          }
        }
        form {
          padding: 10px 15px;
          @media (min-width: $screen-md-min) {
            padding: 20px;
          }
          input {
            width: 230px;
            margin-bottom: 15px;
            border-color: $white;
            color: $white;
            @include placeholder($white);
            @media (min-width: $screen-md-min) {
              border-color: $text-color;
              color: $text-color;
              @include placeholder($text-color);
            }
          }
        }
      }
      .dropdown-toggle {
        @extend .primary-font-medium;
        font-size: 12px;
        text-transform: uppercase;
        @media (min-width: $screen-md-min) {
          padding: 0;
        }
      }
    }
  }
  .navbar-brand {
    padding: 0 15px;
    @media (min-width: $screen-md-min) {
      height: $navbar-height-desktop;
      padding-right: 0;
    }
    img {
      width: auto;
      height: 32px;
      margin: 7px 0;
      @media (min-width: $screen-md-min) {
        width: 131px;
        height: auto;
        margin: 12px 0;
      }
    }
  }
  .navbar-toggle {
    margin-right: 5px;
  }
  .search-engine {
    background-color: $bg-primary;
    background-color: rgba(243, 243, 243, 0.9);
    position: absolute;
    left: 0;
    right: 0;
    .collapse {
      @media (min-width: $screen-md-min) {
        display: block;
        height: auto !important;
      }
    }
    .title {
      display: block;
      color: $text-color;
      padding: 13px 0;
      cursor: pointer;
      span {
        @extend .second-font;
        font-size: 15px;
        vertical-align: middle;
        text-transform: uppercase;
      }
      i {
        font-size: 20px;
        vertical-align: middle;
        margin-left: 10px;
      }
    }
    form {
      padding-bottom: 45px;
      @media (min-width: $screen-md-min) {
        padding-bottom: 20px;
      }
      > .row {
        &.first {
          @media (min-width: $screen-md-min) {
            margin-left: -6px;
            margin-right: -6px;
          }
          > div {
            @media (min-width: $screen-md-min) {
              padding-left: 6px;
              padding-right: 6px;
            }
            &:nth-child(1) {
              > .row {
                @media (min-width: $screen-md-min) {
                  margin-left: -6px;
                  margin-right: -6px;
                }
              }
              @media (min-width: $screen-md-min) {
                width: calc(100% - 100px);
              }
              .form-group {
                @media (min-width: $screen-md-min) {
                  width: 20%;
                  padding-left: 6px;
                  padding-right: 6px;
                }
              }
            }
            &:nth-child(2) {
              @media (min-width: $screen-md-min) {
                width: 100px;
                padding-left: 6px;
                padding-right: 6px;
              }
            }
          }
        }
        &.second {
          @media (min-width: $screen-md-min) {
            margin-left: -6px;
            margin-right: -6px;
          }
          > div {
            @media (min-width: $screen-md-min) {
              padding-left: 6px;
              padding-right: 6px;
            }
            &:nth-child(1) {
              @media (min-width: $screen-md-min) {
                width: 120px;
              }
            }
            &:nth-child(2) {
              @media (min-width: $screen-md-min) {
                width: 150px;
              }
            }
            &:nth-child(3) {
              @media (min-width: $screen-md-min) {
                width: 110px;
              }
            }
            &:nth-child(4) {
              > .row {
                @media (min-width: $screen-md-min) {
                  margin-left: -6px;
                  margin-right: -6px;
                }
              }
              @media (min-width: $screen-md-min) {
                width: calc(100% - 380px);
              }
              .form-group, .checkbox {
                @media (min-width: $screen-md-min) {
                  width: calc(100% / 6);
                  padding-left: 6px;
                  padding-right: 6px;
                }
              }
            }
          }
          input, label {
            padding-right: 8px;
            padding-left: 8px;
          }
          .select2-selection__rendered {
            padding-left: 8px !important;
          }
        }
      }
      .form-group, .checkbox {
        @media (min-width: $screen-md-min) {
          margin: 20px 0 0;
        }
        &.padding-right {
          padding-right: 6px;
        }
        &.padding-left {
          padding-left: 6px;
        }
      }
      input {
        @media (min-width: $screen-md-min) {
          height: $input-height-large;
        }
      }
      input, label {
        @extend .second-font;
        font-weight: normal;
        text-transform: uppercase;
        padding-left: 8px;
        @media (min-width: $screen-md-min) {
          padding-left: $padding-base-horizontal;
        }
      }
      .checkbox {
        margin: 0 0 15px;
        @media (min-width: $screen-md-min) {
          margin: 20px 0 0;
        }
        .content {
          position: relative;
          border: 1px solid $text-color;
          padding-top: 4px;
          padding-bottom: 4px;
          @media (min-width: $screen-md-min) {
            padding-top: 9px;
            padding-bottom: 9px;
          }
        }
        input {
          opacity: 0;
          &:checked {
            + label {
              @extend .icon-checkbox;
            }
          }
        }
        label {
          float: left;
          width: calc(100% - 20px);
          @include text-overflow();
          &:before {
            content: "";
            position: absolute;
            top: 8px;
            right: 8px;
            @extend .icon-square;
            @media (min-width: $screen-md-min) {
              top: 13px;
            }
          }
          &:after {
            position: absolute;
            top: 8px;
            right: 7px;
            display: inline-block;
            @media (min-width: $screen-md-min) {
              top: 13px;
            }
          }
        }
      }
      .radios {
        .col-xs-6 {
          &:nth-child(1) {
            padding-right: 0;
          }
          &:nth-child(2) {
            padding-left: 0;
          }
        }
      }
      input[type="radio"] {
        clip: rect(1px, 1px, 1px, 1px);
        position: absolute !important;
        &:checked {
          + .radio-label {
            background-color: $text-color;
            color: #fff;
          }
        }
      }
      .radio-label {
        @extend .second-font;
        font-size: 15px;
        font-weight: normal;
        text-transform: uppercase;
        cursor: pointer;
        display: inline-block;
        margin: 0;
        padding: 3px 0 4px;
        width: 100%;
        border: 1px solid $text-color;
        @media (min-width: $screen-md-min) {
          padding: 8px 0 9px;
        }
      }
      button {
        width: 100%;
        @media (min-width: $screen-md-min) {
          padding: 9px 12px;
        }
      }
    }
  }
}

li.select2-results__option[id$='allzones']{
  &:before, &:after {
    display: none !important;
  }
}

footer#main-footer {
  color: $white;
  background-color: $black;
  padding: 30px 0;
  a {
    color: $white;
    &:hover {
      border-bottom: 1px solid $white;
    }
  }
  .pages {
    @extend .second-font;
    text-transform: uppercase;
    margin-bottom: 30px;
  }
  .info {
    margin-bottom: 30px;
    a {
      &:hover {
        border: none;
      }
    }
  }
  .legal {
    position: relative;
    font-size: 12px;
    .links {
      > li {
        margin-bottom: 10px;
        @media (min-width: $screen-md-min) {
          display: inline-block;
          margin-right: 50px;
        }
      }
    }
  }
  .social {
    margin-top: 30px;
    li {
      margin-right: 10px;
      @media (min-width: $screen-lg-min) {
        margin-right: 22px;
      }
      svg {
        width: 29px;
        height: 29px;
        &:hover {
          path {
            fill: $brand-primary;
          }
        }
      }
    }
  }
  .logo {
    @media (min-width: $screen-md-min) {
      position: absolute;
      bottom: 40px;
      right: 0;
      width: auto;
    }
    img {
      width: 111px;
      height: 111px;
    }
  }
}

#search-btn {
  width: 15px;
  height: 15px;
  img {
    float: left;
  }
  img:last-of-type {
    display: none;
  }
  &:hover {
    img:first-of-type {
      display: none;
    }
    img:last-of-type {
      display: block;
    }
  }
}
